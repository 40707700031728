.container {
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    height: 100%;
}

@media screen and (max-width: 730px) {
    .container {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .divider {
        display: none;
    }
}

.children-container {
    display: flex;
    flex: 1 1 100%;
    width: 60%;
    align-items: center;
    justify-content: center;
}